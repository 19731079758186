<template>
  <div>
    <div class="vue-form-wizard mb-3 md">
      <div class="wizard-tab-content">
        <!-- step 1 tab -->
        <div
          v-if="setpsList[0].active == true"
          id="step-1"
          class="wizard-tab-container"
        >
          <validation-observer
            ref="stepOneForm"
            #default="{ invalid }"
            tag="form"
            style="overflow: visible"
          >
            <b-row>
              <b-col cols="12">
                <h5 class="tab-title">
                  {{ $t('form_wizard_order.order_data') }}
                </h5>
              </b-col>
              <b-col md="6">
                <text-input
                  id="hr_job_title"
                  v-model="submitData.hr_job_title"
                  :label="$t('form_wizard_order.hr_job_title')"
                  :placeholder="
                    $t('form_wizard_order.hr_job_title_placeholder')
                  "
                  name="hr_job_title"
                  rules="required"
                />
              </b-col>
              <b-col md="6">
                <text-input
                  id="employee_job_title"
                  v-model="submitData.employee_job_title"
                  :label="$t('form_wizard_order.employee_job_title')"
                  :placeholder="
                    $t('form_wizard_order.employee_job_title_placeholder')
                  "
                  name="employee_job_title"
                  rules="required"
                />
              </b-col>
              <b-col md="6">
                <wameed-dropdown
                  v-if="getAllCompaniesForSelect"
                  id="company"
                  v-model="submitData.company"
                  :label="$t('form_wizard_order.company_name')"
                  :placeholder="
                    $t('form_wizard_order.company_name_placeholder')
                  "
                  classes="text-med-16 w-100"
                  value-classes=" text-med-16  text-font-secondary"
                  title="name"
                  variant="disable"
                  :items="getAllCompaniesForSelect.content"
                  text="name"
                  name="company"
                  rules="required"
                />
              </b-col>
              <b-col md="6">
                <text-input
                  id="required_jobs_no"
                  v-model="submitData.required_jobs_no"
                  :label="$t('form_wizard_order.required_jobs_no')"
                  :placeholder="
                    $t('form_wizard_order.required_jobs_no_placeholder')
                  "
                  name="required_jobs_no"
                  rules="required|numeric"
                />
              </b-col>
              <b-col md="6">
                <wameed-dropdown
                  v-if="getIndustryPreference"
                  id="industry_preference"
                  v-model="submitData.industry_preference"
                  :label="$t('form_wizard_order.industry_preference')"
                  :placeholder="
                    $t('form_wizard_order.industry_preference_placeholder')
                  "
                  classes="text-med-16 w-100"
                  value-classes="  text-med-16  text-font-secondary"
                  title="name"
                  variant="disable"
                  :items="getIndustryPreference.content"
                  text="name"
                  name="industry_preference"
                  rules="required"
                />
              </b-col>
              <b-col md="6">
                <wameed-dropdown
                  v-if="getRolePreference"
                  id="role_preference"
                  v-model="submitData.role_preference"
                  :label="$t('form_wizard_order.role_preference')"
                  :placeholder="
                    $t('form_wizard_order.role_preference_placeholder')
                  "
                  classes="text-med-16 w-100"
                  value-classes="  text-med-16  text-font-secondary"
                  title="name"
                  variant="disable"
                  :items="getRolePreference.content"
                  text="name"
                  name="role_preference"
                  rules="required"
                  :multiple="true"
                />
              </b-col>
              <b-col md="12">
                <wameed-dropdown
                  v-if="getServices"
                  id="services"
                  v-model="submitData.services"
                  :label="$t('form_wizard_order.services')"
                  :placeholder="$t('form_wizard_order.services_placeholder')"
                  classes="text-med-16 w-100"
                  value-classes="text-med-16 text-font-secondary"
                  title="name"
                  variant="disable"
                  :items="getServices.content"
                  text="name"
                  name="services"
                  rules="required"
                  :multiple="true"
                />
              </b-col>
              <b-col
                md="11"
                cols="9"
              >
                <text-area
                  id="description"
                  v-model="submitData.description"
                  :label="$t('form_wizard_order.description')"
                  :placeholder="$t('form_wizard_order.description_placeholder')"
                  input-classes="text-med-14 text-font-main"
                  name="description"
                  rules="required"
                  :rows="rowsNum"
                  field-classes="mb-md-3 mb-0"
                />
              </b-col>
              <b-col
                md="1"
                cols="3"
                class="px-0 label-height"
              >
                <multi-images-upload
                  id="file"
                  field-classes="mb-md-3 mb-0"
                  :max="1"
                  clear-all=""
                  :label="$t('common.empty_string')"
                  name="file"
                  rules="required"
                  file-type="file"
                  :title="$t('orders_page.attach_job_description')"
                  @changed="updateImages"
                />
              </b-col>
              <b-col md="12">
                <h5 class="tab-title" />
                <wameed-btn
                  classes="w-100 text-bold-14 text-white rounded-14"
                  :title="$t('orders_page.add_on')"
                  :disabled="invalid"
                  type="button"
                  variant="main"
                  @submitAction="submitOrder"
                />
              </b-col>
            </b-row>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import WameedBtn from '@/components/WameedBtn.vue';
import TextInput from '@/components/TextInput.vue';
import WameedDropdown from '@/components/WameedDropdown.vue';
import PackageContent from '@/components/pages/applicants/PackageContent.vue';
import MultiImagesUpload from '@/components/multiImagesUpload.vue';
import FormWizard from '@/components/vue-form-wizard/FormWizard.vue';
import TabContent from '@/components/vue-form-wizard/TabContent.vue';
import '@/assets/scss/wizard.scss';
// import 'vue-form-wizard/dist/vue-form-wizard.min.css';

import PolicyModal from '@/components/pages/applicants/PolicyModal.vue';
import Calender from '@/components/Calender.vue';
import TextArea from '@/components/TextArea.vue';
import CustomStep from '@/components/pages/applicants/CustomStep.vue';
import SetpsWizard from '@/components/steps-wizard/WizardNumber.vue';

export default {
  components: {
    WameedBtn,
    WameedDropdown,
    TextInput,
    MultiImagesUpload,
    PackageContent,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    PolicyModal,
    Calender,
    TextArea,
    CustomStep,
    SetpsWizard,
  },

  data() {
    return {
      rowsNum: '4',
      dateRange: [],
      setpsList: [
        {
          active: true,
          checked: false,
          disabled: false,
          tabId: 1,
          title: this.$i18n.t('form_wizard_order.order_data'),
          subtitle: this.$i18n.t('form_wizard_order.order_data_subtitle'),
        },
        {
          active: false,
          checked: false,
          disabled: true,
          tabId: 2,
          title: this.$i18n.t('form_wizard_order.payment_data'),
          subtitle: this.$i18n.t('form_wizard_order.payment_data_subtitle'),
        },
        {
          active: false,
          checked: false,
          disabled: true,
          tabId: 3,
          title: this.$i18n.t('form_wizard_order.add_success'),
          subtitle: this.$i18n.t('form_wizard_order.add_success_subtitle'),
        },
      ],
      submitData: {
        hr_job_title: null,
        employee_job_title: null,
        industry_preference: null,
        company: null,
        required_jobs_no: null,
        role_preference: [],
        services: [],
        description: null,
        file: [],
      },

      // DATA THAT WILL BE SUBMITTED - END
    };
  },
  computed: {
    ...mapGetters({
      getIndustryPreference: 'getIndustryPreference',
      getRolePreference: 'getRolePreference',
      getServices: 'getServices',
      getAllCompaniesForSelect: 'getAllCompaniesForSelect',
    }),
  },
  watch: {},
  created() {
    this.maxWidth();
    this.loadIndustryPreference();
    this.loadRolePreference();
    this.loadAllServices();
    this.loadAllCompaniesForSelect();
  },
  methods: {
    maxWidth() {
      if (window.innerWidth < 960) {
        this.rowsNum = '2';
      }
    },

    ...mapActions({
      loadIndustryPreference: 'loadIndustryPreference',
      loadRolePreference: 'loadRolePreference',
      loadAllServices: 'loadAllServices',
      loadAllCompaniesForSelect: 'loadAllCompaniesForSelect',
      addOrder: 'addOrder',
    }),
    submitOrder() {
      this.addOrder({
        employee_seeker_id: this.submitData.company.id,
        hr_job_title: this.submitData.hr_job_title,
        employee_job_title: this.submitData.employee_job_title,
        industry_preference: this.submitData.industry_preference,
        required_jobs_no: this.submitData.required_jobs_no,
        role_preference: this.submitData.role_preference,
        services: this.submitData.services,
        description: this.submitData.description,
        file:
          this.submitData.file && this.submitData.file.length > 0
            ? this.submitData.file[0].file_name
            : null,
      }).then(() => {
        this.$router.push({
          name: 'orders',
          params: { lang: this.$i18n.locale },
        });
      });
    },
    updateImages(images) {
      this.submitData.file = images;
    },
  },
};
</script>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
