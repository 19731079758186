<template>
  <div class="wameed-dashboard-page-content">
    <page-header
      :title="$t('orders_page.add_order')"
      :sub-title="$t('orders_page.add_order_subtitle')"
    />

    <FormWizard />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import FormWizard from './form-wizard/FormWizardNumber.vue';
export default {
  components: {
    FormWizard,
    PageHeader,
  },
};
</script>
